import React from "react";
import LoginHistoryController, {
    Props,
} from "./LoginHistoryController";
import moment from 'moment'

export default class LoginHistory extends LoginHistoryController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {
            loginHistoryData,
            loader
        } = this.state;
        return (
            <div>
                {loader == true ?
                    <div className="screen-loader-center">
                        <div className="ct_center_loader">
                            <span className="loader"></span></div>
                    </div> :
                    <div>
                        <p className="mb-4">Logins across all users on your account over the last 90 days.</p>
                        <div className="ct_white-box h-auto">
                            <div className="p-0">
                                <div className="table-responsive">
                                    <table className="table ct_table_bg table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Date
                                        </th>
                                                <th>
                                                    Username
                                        </th>
                                                <th>
                                                    IP
                                        </th>
                                                <th>
                                                    Permission Level
                                        </th>
                                                <th>Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loginHistoryData.map((ob: any) =>
                                                <tr>
                                                    <td>{moment(ob.login_time).format("YYYY/MM/DD h:mm")}</td>
                                                    <td>{ob.username}</td>
                                                    <td></td>
                                                    <td>{ob.permission_level}</td>
                                                    <td>{ob.status}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </div >
        );
    }
}
