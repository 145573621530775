import React, { FormEvent, ReactElement } from 'react';
import styles from './form.module.scss';

interface FormProps {
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    children: ReactElement[];
}

const Form = (props: FormProps) => {
    return (
        <form onSubmit={props.onSubmit} className={styles.form}>
            {props.children}
        </form>
    )
}

export default Form;
