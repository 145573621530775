import React, { ChangeEvent } from "react";
import styles from "./textfield.module.scss";

interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  value: string | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  label: string;
  helperText?: string;
  required?: boolean;
  placeholder?: string;
}

const Select = (props: SelectProps) => {
  return (
    <div>
      <label className={styles.label} htmlFor="country">
        Select Country {props.required && <span style={{ color: props.helperText ? 'red' : '#4c1986' }}>*</span>}
      </label>
      <select
        className="form-select form-control"
        id="country"
        value={props.value}
        placeholder={props.placeholder}
        required
        onChange={props.onChange}
      >
        {props.options &&
          props.options.map((item: Option) => (
            <option value={item.value}>{item.label}</option>
          ))}
      </select>
      {props.helperText && (
        <span className={styles.helperText}>{props.helperText}</span>
      )}
    </div>
  );
};

export default Select;
