import { IBlock } from "../../framework/IBlock";
import { Message } from "../../framework/Message";
import { BlockComponent } from "../../framework/BlockComponent";
import { message as MESSAGE } from "antd";
import axios from "axios";
export const configJSON = require("../config");

export interface Props {
}

interface S {
    email: any;
    isFlag: any;
    isuser: any;
    number: any;
    userData: any;
    loader: any;
}

interface SS {
    id: any;
}

export default class UsersController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiLoginAccount: string = "";

    constructor(props: Props) {
        super(props);
        this.state = this.getEmptyState();
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getAllUserData();
    }

    getEmptyState = () => ({
        email: "",
        isFlag: false,
        isuser: "",
        number: "",
        userData: [],
        loader: false,
    });

    openModal = () => {
        this.setState({
            isFlag: true
        });
    }

    closeModal = () => {
        this.setState({
            isFlag: false
        });
    }

    getAllUserData = () => {
        const val = localStorage.getItem("user_email") || "";
        const data = { email: val }
        const token = localStorage.getItem("token") || "";
        axios({
            method: configJSON.apiPostMethod,
            url: configJSON.baseUrl + configJSON.getAllUsersEndPointURL,
            data: data,
            headers: {
                "Content-Type": configJSON.formUrlenCodedContentType,
                "Authorization": `Bearer ${token}`
            }
        })
            .then((res) => {
                this.setState({
                    loader: false
                })
                if (res.data.success == true) {
                    this.setState({
                        userData: res.data.all_users
                    });
                }
                else {
                    this.setState({
                        userData: []
                    });
                }
            })
            .catch((err) => {
                console.log({ err })
                this.setState({
                    loader: false
                })
            });
    }



    onHandleAddUser = (e: any) => {
        this.setState({
            loader: true
        })
        e.preventDefault()
        const data = {
            email: this.state.email,
            username: this.state.isuser,
            phone_number: this.state.number,
        }
        if (this.state.email && this.state.isuser && this.state.number) {
            const token = localStorage.getItem("token");
            axios({
                method: configJSON.apiPostMethod,
                url: configJSON.baseUrl + configJSON.addNewUserEndPointURL,
                data: data,
                headers: {
                    "Content-Type": configJSON.formUrlenCodedContentType,
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((res) => {
                    this.setState({
                        email: configJSON.emptyValue,
                        isuser: configJSON.emptyValue,
                        number: configJSON.emptyValue,
                        loader: false
                    })

                    if (res.data.success == true) {
                        MESSAGE.success(res.data.message, 5);
                            this.getAllUserData();
                            this.closeModal();
                    }
                    else {
                        MESSAGE.error(res.data.message, 5);
                        this.closeModal();
                    }
                })
                .catch((err) => {
                    this.setState({
                        email: configJSON.emptyValue,
                        isuser: configJSON.emptyValue,
                        number: configJSON.emptyValue,
                        loader: false
                    })
                });
        }
        else {
            MESSAGE.error("All field are maindetory")
            this.setState({
                loader: false
            })
        }
    }
}
