import React, { ChangeEvent, FormEvent, FormEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import { message } from "antd";

import TextField from "../../../common/TextField";
import PasswordField from "../../../common/PasswordField";
import PhoneInput from "../../../common/PhoneInput";
import Button from "../../../common/Button";
import { UserSignup } from "../../../types/user";
import FormValidation from "../../../framework/FormValidation";
import { SignupSchema } from "../../../schema/user";
import UserController from "../../../controllers/user";
import BaseAuthForm from "../../../common/BaseAuthForm";

import styles from "./signup.module.scss";

interface SignupProps {
  history: any;
}

const Signup = (props: SignupProps) => {
  const emptyState = {
    email: "",
    password: "",
    phone: "",
    username: "",
  };
  const [data, setData] = useState<UserSignup>(emptyState);
  const [errorText, setErrorText] = useState<{ [key: string]: string }>(
    emptyState
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setData({
        ...data,
        [field]: e.target.value,
      });
      setErrorText({
        ...errorText,
        [field]: "",
      });
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = FormValidation.validateForm(SignupSchema, data);
    if (FormValidation.hasErrors(errors)) {
      setErrorText({
        ...errors,
      });
      return;
    }
    setLoading(true);
    try {
      const controller = new UserController();
      const result = await controller.signupUser(data);
      setLoading(false);
      message.success(result, 5);
      props.history.push({
        pathname: "/otp/verify",
        state: {
          email: data.email,
          username: data.username,
          phone: data.phone,
        },
      });
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        message.error(err.message, 5);
      }
    }
  };

  return (
    <div>
      {loading && (
        <div className="screen-loader-center">
          <div className="ct_center_loader">
            <span className="loader"></span>
          </div>
        </div>
      )}
      <BaseAuthForm title="Sign up">
        <form onSubmit={handleSubmit}className={styles.form}>
          <TextField
            id="email"
            value={data.email}
            onChange={handleChange('email')}
            errorText={errorText.email}
            label="Email"
          />
          <PhoneInput
            value={data.phone}
            onChange={(value) => {
              setData({ ...data, phone: value })
              setErrorText({ ...errorText, phone: '' })
            }}
            errorText={errorText.phone}
          />
          <TextField
            id="username"
            value={data.username}
            onChange={handleChange('username')}
            errorText={errorText.username}
            label="Username"
            infoText={
              <ul>
                <li>
                  Minimum of 5 characters and maximum of 50
                  characters
                </li>
                <li>Alphabets, numbers and underscore are allowed</li>
              </ul>
            }
          />
          <div className={styles.inputField}>
            <PasswordField
              password={data.password}
              onChange={handleChange('password')}
              errorText={errorText.password}
              showInfo={true}
            />
          </div>
          <p className={styles.terms} style={{ fontSize: '1rem' }}>
            By creating an account, You agree to our &nbsp;
            <a
              href="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/Bitnimbus%20Terms%20and%20Condition%206.30.2024.pdf"
              title="Terms of Service"
              target="__blank"
            >
              Terms of Service
            </a>
            &nbsp; and &nbsp;
            <a
              href="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/Bitnimbus%20Privacy%20Policy.pdf"
              title="Privacy Policy"
              target="__blank"
            >
              Privacy Policy
            </a>
          </p>
          <Button type='submit' label="Sign up" />
          <hr />
          <div className={styles.formFooter}>
            <p>Already have an account?</p>
            <Link to="/">
              <Button label="Log In" onClick={() => {}} variant="outlined" />
            </Link>
          </div>
        </form>
      </BaseAuthForm>
    </div>
  )
};

export default Signup;
