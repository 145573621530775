import React, { useState } from "react";

import ViewIcon from "../components/style/icons/view.svg";
import HideIcon from "../components/style/icons/hide.svg";
import InfoIcon from "../components/style/icons/info.svg";
import Tooltip from "./Tooltip";

import styles from "./PasswordField.module.scss";

interface PasswordFieldProps {
  password: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errorText?: string;
  showInfo?: boolean;
  required?: boolean;
}

const PasswordField = (props: PasswordFieldProps) => {
  const { password } = props;
  const [showPassword, setShowPassword] = useState(false);

  const passwordPolicy = (
    <ul className={styles.policyList}>
      <li>Minimum of 12 characters and maximum of 24 characters</li>
      <li>At Least 1 uppercase, 1 lowercase, 1 digit and 1 special character other than whitespace is required</li>
    </ul>
  );

  return (
    <div>
      <div className={styles.root}>
        <label className={styles.label}>
          {props.label ? props.label : "Password"}
          {props.required && (
             <span style={{ color: props.errorText ? 'red' : 'inherit' }}>
             *
            </span>
          )}
          {props.showInfo && (
            <Tooltip title={passwordPolicy}>
              <img className={styles.tooltipIcon} src={InfoIcon} />
            </Tooltip>
          )}
        </label>
        <img
          src={!showPassword ? ViewIcon : HideIcon}
          className={styles.icon}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <div>
        <input
          type={showPassword ? "text" : "password"}
          // required
          className={`form-control ${styles.formControl}`}
          onChange={props.onChange}
          value={password}
        />
        {props.errorText && (
          <span className={styles.helperText}>{props.errorText}</span>
        )}
      </div>
    </div>
  );
};

export default PasswordField;
