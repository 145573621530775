const apiEndpoints = {
  LOCAL: 'http://localhost:9000/users/',
  STAGING:  'https://login-api-staging.bitnimbus.io/users/',
  PROD: 'https://login-api-prod.bitnimbus.io/users/',
};

Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.apiGetMethod = "GET";
  exports.apiPostMethod = "POST";
  exports.apiPutMethod = "PUT";
  exports.apiDeleteMethod = "DELETE";
  exports.apiPatchMethod = "PATCH"; 

  exports.baseUrl = apiEndpoints[(process.env.REACT_APP_ENV || 'LOCAL')]; 
  exports.jsonDataContentType = "application/json";
  exports.formUrlenCodedContentType = "application/x-www-form-urlencoded";
  exports.emptyValue = "";

  exports.registerAccountEndPoint = "signUp";
  exports.loginAccountEndPoint = "login";
  exports.otpCheckEndPoint = "verify/otp";
  exports.otpResendEndpoint = "resend/otp";
  exports.passwordResendEndpoint = "resend/password"
  exports.forgotPasswordEndPointURL = "forgotPassword";
  exports.forgotUsernameEndpoint = 'forgot/username';
  exports.getUserByACTtokenEndpoint = "details/act_token";
  exports.verifyEmailEndPoint = "confirm/account";
  exports.createBillingDetailandVerifyUser = "verify/billing";

  // exports.baseUrl1 = "http://localhost:9000/"
  // exports.baseUrl1 = "http://54.243.134.173:80/"
  exports.baseUrl1 = "https://login-api-staging.bitnimbus.io/"
  exports.dashboard = 'https://login-staging.bitnimbus.io/'
