import React from "react";
import cx from "classnames";

import BillingInfoController, { Props } from "./BillingInfoController";
import Logo from "../../style/logo.svg";
import VerifiedIcon from "../../style/icons/verified.svg";
import TextField from "../../../common/TextField";
import styles from "./billing_info.module.scss";
import { Link } from "react-router-dom";
import Card from "../../../common/Card";
import Button from "../../../common/Button";
import Select from "../../../common/Select";
export const configJSON = require("../../config");

export default class BillingInfo extends BillingInfoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      first_name,
      last_name,
      company_name,
      address_line_1,
      country,
      city,
      state,
      postal_code,
      countriesList,
      userData,
      loader,
      address_line_2
    } = this.state;

    if (!userData) {
      return (
        <div className="screen-loader-center">
          <div className="ct_center_loader">
            <span className="loader"></span>
          </div>
        </div>
      );
    }
    const alreadyVerified = (
      <Card title="Your account is already verified">
        <div className="text-center">
          <img
            style={{ width: "auto", height: "4rem" }}
            id="signup_banner"
            src={VerifiedIcon}
          />
          <div style={{ marginTop: "2rem" }}>
            <Link to="/">
              <button className={styles.submitBtn}>Login</button>
            </Link>
          </div>
        </div>
      </Card>
    );

    const billingForm = (
      <Card>
        <div>
          <h4 className={`${styles.title} mb-0`} >
            Add Your Personal Details
          </h4>
          <form className={styles.form} onSubmit={this.onHandleSubmitBillingInfo}>
            <div className="row align-items-center">
              <div className="col-md-6 mt-5">
                <div className="ct_flex_box">
                  <div style={{ width: "100%" }} className="ct_pay_icon">
                    <TextField
                      id="first_name"
                      value={first_name}
                      onChange={this.handleChange("first_name")}
                      label="First Name"
                      placeholder="First Name"
                      required
                      errorText={this.state.errorText.first_name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <div className="ct_pay_btn">
                  <TextField
                    id="last_name"
                    value={last_name}
                    onChange={this.handleChange("last_name")}
                    label="Last Name"
                    placeholder="Last Name"
                    required
                    errorText={this.state.errorText.last_name}
                  />
                </div>
              </div>
            </div>
            <TextField
              id="company_name"
              value={company_name}
              onChange={this.handleChange("company_name")}
              label="Company Name"
              placeholder="Company Name"
              errorText={this.state.errorText.company_name}
            />
            <TextField
              id="address_line_1"
              value={address_line_1}
              onChange={this.handleChange("address_line_1")}
              placeholder="Address Line 1"
              label="Address Line 1"
              required
              errorText={this.state.errorText.address_line_1}
            />
            <TextField
              id="address_line_2"
              value={address_line_2}
              onChange={this.handleChange("address_line_2")}
              placeholder="Address Line 2"
              label="Address Line 2"
              errorText={this.state.errorText.address_line_2}
            />
            <div className="row align-items-center">
              <div
                className={cx({
                  "col-md-6": true,
                })}
              >
                <TextField
                  id="city"
                  value={city}
                  onChange={this.handleChange("city")}
                  placeholder="City"
                  label="City"
                  required
                  errorText={this.state.errorText.city}
                />
              </div>
              <div
                className={cx({
                  "col-md-6": true,
                })}
              >
                <TextField
                  id="state"
                  value={state}
                  onChange={this.handleChange("state")}
                  placeholder="State"
                  label="State"
                  required
                  errorText={this.state.errorText.state}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div
                className={cx({
                  "col-md-6": true,
                })}
              >
                <TextField
                  id="postal_code"
                  value={postal_code}
                  onChange={this.handleChange("postal_code")}
                  placeholder="Zip Code"
                  label="Zip Code"
                  required
                  errorText={this.state.errorText.postal_code}
                />
              </div>
              <div
                className={cx({
                  "col-md-6": true,
                })}
              >
                <Select
                  label="Select Country"
                  required
                  onChange={(e) =>
                    this.setState({
                      country: e.target.value,
                      errorText: {
                        ...this.state.errorText,
                        country: "",
                      },
                    })
                  }
                  helperText={this.state.errorText.country}
                  placeholder="Select Country"
                  value={country}
                  options={
                    countriesList
                      ? countriesList.map((item: any) => ({ label: item.name, value: item.code }))
                      : []
                  }
                />
              </div>
            </div>
            <div>
              <Button
                label="Update and Verify Account"
                type="submit"
                loading={loader}
              />
            </div>
          </form>
        </div>
      </Card>
    );

    const invalidToken = (
      <Card title="Invalid Token">
        <div>
          <p className="text-center">
            Couldn't find an account related to the activation token
            provided.You should have recieved an email with a link to complete
            the sign up process so that you can log into your account and let us
            know your info to finish setting up your stacksville account. We can
            then provide our services to you.
          </p>
        </div>
      </Card>
    );

    const getComponent = () => {
      return userData.verified === 1
        ? alreadyVerified
        : userData.message === "Invalid Activation tocken"
        ? invalidToken
        : billingForm;
    };
    return getComponent();
  }
}
