import React, { useState } from 'react'

const Headers = (props: any) => {
    const [flag, setFlag] = useState(false);
    const onHandleLogout = () => {
        localStorage.clear();
        window.location.href = '/'
    }
    const onHandleClick = () => {
        if (flag === false) {
            setFlag(true);
        }
        else {
            setFlag(false);
        }
    }
    return (
        <>
            < header id="header" className="header fixed-top d-flex align-items-center" >

                <div className="d-flex align-items-center justify-content-between">
                    <a href="index.html" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Bitnimbus</span>
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn"></i>
                </div>

                <div className="dropdown ct_custom_drop">
                    <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        Create
  </a>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                            <a className="dropdown-item" href="#">
                                <div className="ct_drop_flex">
                                    <div className="ct_drop_title">
                                        <h5>Linode</h5>
                                        <p>High performance SSD Linux servers</p>
                                    </div>
                                </div>
                            </a></li>
                        <li><a className="dropdown-item" href="#">
                            <div className="ct_drop_flex">

                                <div className="ct_drop_title">
                                    <h5>Volume</h5>
                                    <p>Attach additional storage to your Linode</p>
                                </div>
                            </div>
                        </a></li>
                        <li><a className="dropdown-item" href="#">
                            <div className="ct_drop_flex">
                                <div className="ct_drop_title">
                                    <h5>NodeBalancer</h5>
                                    <p>Ensure your services are highly available</p>
                                </div>
                            </div>
                        </a></li>

                        <li><a className="dropdown-item" href="#">
                            <div className="ct_drop_flex">
                                <div className="ct_drop_title">
                                    <h5>Firewall</h5>
                                    <p>Control network access to your Linodes</p>
                                </div>
                            </div>
                        </a></li>
                    </ul>
                </div>
                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="#">
                                <i className="bi bi-search"></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown">

                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell"></i>
                                <span className="badge bg-primary badge-number"></span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li className="dropdown-header">
                                    You have 4 new notifications
                                   <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="notification-item">
                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                    <div>
                                        <h4>Lorem Ipsum</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>30 min. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-x-circle text-danger"></i>
                                    <div>
                                        <h4>Atque rerum nesciunt</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>1 hr. ago</p>
                                    </div>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="notification-item">
                                    <i className="bi bi-check-circle text-success"></i>
                                    <div>
                                        <h4>Sit rerum fuga</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>2 hrs. ago</p>
                                    </div>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="notification-item">
                                    <i className="bi bi-info-circle text-primary"></i>
                                    <div>
                                        <h4>Dicta reprehenderit</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>4 hrs. ago</p>
                                    </div>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="dropdown-footer">
                                    <a href="#">Show all notifications</a>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <div className="icon">
                                    <i className="bi bi-people"></i>
                                </div>
                            </a>
                        </li>

                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                                <span>Profile</span>
                                <span className="d-none d-md-block dropdown-toggle ps-2" onClick={onHandleClick}>testuser-123  </span>
                            </a>
                            <ul className={flag == true ? "dropdown-menu show dropdown-menu-end dropdown-menu-arrow profile" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"}>
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-gear"></i>
                                        <span>Account Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                        <i className="bi bi-question-circle"></i>
                                        <span>Need Help?</span>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <div className="dropdown-item d-flex align-items-center">
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span onClick={onHandleLogout}>Sign Out</span>
                                    </div>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header >
        </>
    )
}

export default Headers