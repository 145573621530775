import React from "react";
import cx from "classnames";
import Input, { Value } from "react-phone-number-input";

import styles from "./phoneinput.module.scss";

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
}

const PhoneInput = (props: PhoneInputProps) => {
  return (
    <div className={styles.group}>
      <label className={styles.label}>Phone Number</label>
      <Input
        className={cx({
          "form-control": true,
          [styles.phoneInput]: true,
        })}
        placeholder=""
        defaultCountry="US"
        countries={["IN", "US"]}
        addInternationalOption={false}
        value={props.value}
        onChange={props.onChange}
      />
      {props.errorText && (
        <div className={styles.helperText}>{props.errorText}</div>
      )}
    </div>
  );
};

export default PhoneInput;
