import { IBlock } from "../../framework/IBlock";
import { Message } from "../../framework/Message";
import { BlockComponent } from "../../framework/BlockComponent";
import { message as MESSAGE } from "antd";
import axios from "axios";
export const configJSON = require("../config");

export interface Props {
}

interface S {
   val:any;
}

interface SS {
    id: any;
}

export default class UserSettingController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiLoginAccount: string = "";

    constructor(props: Props) {
        super(props);
        this.state = this.getEmptyState();
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    getEmptyState = () => ({
        val:""
    });

}
