import React, { ReactNode } from 'react';
import { Tooltip as AntTooltip } from 'antd';

interface TooltipProps {
    title: ReactNode;
    children: ReactNode;
}

const Tooltip = (props: TooltipProps) => {
    return (
        <AntTooltip title={props.title} placement="right" color="#f2f2f2e6" overlayStyle={{
            border: '1px solid gray',
            borderRadius: '8px'
          }} overlayInnerStyle={{
            color: '#5e5b5d'
          }}>
            {props.children}
          </AntTooltip>
    );
}

export default Tooltip;
