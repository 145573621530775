import { AxiosError, AxiosInstance } from "axios";
import { getClient } from "./api";
import { UserLogin, UserSignup } from "../types/user";

const config = require("../components/config");

class UserController {
  client: AxiosInstance;

  public constructor() {
    this.client = getClient();
  }

  getError(err: any) {
    if (err instanceof AxiosError) {
      return new Error(err.response?.data.message || "something went wrong");
    }
    return new Error("something went wrong");
  }

  public async signupUser(user: UserSignup) {
    try {
      const res = await this.client.post(config.registerAccountEndPoint, user);
      if (res.data.success) {
        return res.data.message;
      }
      throw new Error(res.data.detail);
    } catch (err) {
      throw this.getError(err);
    }
  }

  public async loginUser(credentials: UserLogin) {
    try {
      const res = await this.client.post(config.loginAccountEndPoint, credentials);
      return res.data;
    } catch(err) {
      throw this.getError(err);
    }
  }
}

export default UserController;
