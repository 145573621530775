import { BlockComponent } from "../../../framework/BlockComponent";
import { message as MESSAGE } from "antd";
import axios, { AxiosError } from "axios";
import { ResetPasswordSchema } from "../../../schema/user";
import FormValidation from "../../../framework/FormValidation";
import { FormEvent } from "react";
export const configJSON = require("../../config");

export interface Props {
  history: any;
  match: any;
}

interface S {
  act_token: any;
  password: any;
  confirmPassword: any;
  loader: any;
  success: boolean;
  errorText: { [key: string]: string };
}

interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiLoginAccount: string = "";

  constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  getEmptyState = () => ({
    act_token: this.props.match.params.act_token,
    password: "",
    confirmPassword: "",
    loader: false,
    success: false,
    errorText: {},
  });

  isValidForm = () => {
    return (
      this.state.confirmPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  };

  onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      loader: true,
    });
    const data = {
      act_token: this.state.act_token,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    const errors = FormValidation.validateForm(ResetPasswordSchema, {
      password: data.password,
      confirmPassword: data.confirmPassword,
    });
    if (FormValidation.hasErrors(errors)) {
        this.setState({
            loader: false,
            errorText: {
                ...errors,
            }
        });
        return;
    }
    if (!this.isValidForm()) {
      this.setState({
        loader: false,
        errorText: {
          confirmPassword: 'Password does not match'
        }
      });
      return;
    }
    axios({
      method: configJSON.apiPostMethod,
      url: configJSON.baseUrl + configJSON.forgotPasswordEndPointURL,
      data: data,
      headers: { "Content-Type": configJSON.formUrlenCodedContentType },
    })
      .then((res) => {
        this.setState({
          loader: false,
        });
        if (res.data.success == true) {
          MESSAGE.success(res.data.message, 5);
          this.setState({
            success: true,
          });
        } else {
          MESSAGE.error(res.data.message, 5);
        }
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
             this.setState({
                loader: false,
            });
            MESSAGE.error(err.response?.data.message || 'something went wrong', 5);
        }
      });
  };
}
