import React, { FormEvent, useState } from 'react';
import EmailSentIcon from '../../style/icons/Email_verified.svg';
import Card, { CardContent } from '../../../common/Card';
import TextField from '../../../common/TextField';
import Button from '../../../common/Button';
import { Link } from 'react-router-dom';
import FormValidation from '../../../framework/FormValidation';
import { ForgotUsernameSchema } from '../../../schema/user';
import axios, { AxiosError } from 'axios';
import { message } from 'antd';
import { getErrorMessage } from '../../../controllers/api';
export const configJSON = require('../../config');

interface Props {}
interface State {
  loader: boolean;
  emailSent: boolean;
  email: string;
  errorText: string;
}

const ForgotUsername = (props: Props) => {
  const emptyState = {
    loader: false,
    emailSent: false,
    email: '',
    errorText: '',
  };
  const [state, setState] = useState<State>({
    ...emptyState,
  });
  const onHandleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setState({
      ...state,
      loader: true,
    });
    const errors = FormValidation.validateForm(ForgotUsernameSchema, {
      email: state.email,
    });
    if (FormValidation.hasErrors(errors)) {
      setState({
        ...state,
        loader: false,
        errorText: errors.email,
      });
      return;
    }
    axios({
      method: configJSON.apiPostMethod,
      url: configJSON.baseUrl + configJSON.forgotUsernameEndpoint,
      headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
      data: {
        email: state.email,
      },
    })
      .then((res) => {
        setState({
          ...state,
          loader: false,
        });
        if (res.data.success == true) {
          setState(emptyState);
          setState({
            ...state,
            emailSent: true,
            loader: false,
          });
        } else {
          setState({
            ...state,
            loader: false,
            errorText: res.data.message
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          loader: false,
          errorText: getErrorMessage(err)
        });
      });
  };

  const emailSentMessage = (
    <div>
      <CardContent>
        <img src={EmailSentIcon} style={{ height: '8.33rem', width: 'auto' }} />
      </CardContent>
      <CardContent>
        <p style={{ marginTop: '2rem', fontSize: '1.2rem' }}>
          If the email address you provided is in our system, we've sent you an
          email with instructions. If you don't see it, please ensure you
          entered a valid address and check your spam folder.
        </p>
      </CardContent>
      <CardContent>
        <Link to="/">
          <Button label="Login" />
        </Link>
      </CardContent>
    </div>
  );
  return (
    <div className="ct_main_bg">
      <div className="container-fluid">
        <Card
          title={
            state.emailSent ? 'Username details sent!' : 'Forgot Your Username?'
          }
        >
          {state?.emailSent ? (
            emailSentMessage
          ) : (
            <form onSubmit={onHandleSubmit}>
              <CardContent>
                <p>
                  Please enter your registered email address of your Bitnimbus
                  account
                </p>
              </CardContent>
              <CardContent>
                <TextField
                  id="username"
                  required
                  value={state.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({ ...state, email: e.target.value, errorText: '' })
                  }
                  placeholder="Enter your Email"
                  label="Email"
                  errorText={state.errorText}
                  dataTestId="forgot-username__email-input"
                />
              </CardContent>

              <CardContent>
                <Button
                  type="submit"
                  label="Send Email"
                  dataTestId="forgot-username__submit"
                  loading={state.loader}
                />
              </CardContent>
              <CardContent>
                <p>
                  Already know your username? Go to{' '}
                  <Link to="/">
                    <strong>Login</strong>
                  </Link>
                </p>
              </CardContent>
            </form>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ForgotUsername;
