import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { message as MESSAGE } from 'antd';
import axios from 'axios';
import { BlockComponent } from '../../../framework/BlockComponent';
import EmailSentIcon from '../../style/icons/email_sent.svg';
import Card from '../../../common/Card';
import TextField from '../../../common/TextField';
import { ForgotPasswordSchema } from '../../../schema/user';
import FormValidation from '../../../framework/FormValidation';
import Button from '../../../common/Button';
import styles from '../../../common/card.module.scss';
import resetPasswordstyles from './resetPassword.module.scss';
import Form from '../../../common/Form';
import { getErrorMessage } from '../../../controllers/api';

export const configJSON = require('../../config');

export interface Props {
  history: any;
}

interface S {
  username: string;
  loader: boolean;
  email_sent: boolean;
  errorText: string;
}

interface SS {
  id: any;
}

export default class ResetPassword extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
  }

  getEmptyState = () => ({
    username: '',
    loader: false,
    email_sent: false,
    errorText: '',
  });

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.email_sent) {
      setTimeout(() => {
        this.props.history.push('/');
      }, 5000);
    }
  }

  onHandleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({
      loader: true,
    });
    const errors = FormValidation.validateForm(ForgotPasswordSchema, {
      username: this.state.username,
    });
    if (FormValidation.hasErrors(errors)) {
      this.setState({
        loader: false,
        errorText: errors.username,
      });
      return;
    }

    axios({
      method: configJSON.apiGetMethod,
      url:
        configJSON.baseUrl +
        configJSON.passwordResendEndpoint +
        `/${this.state.username}`,
      headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
    })
      .then((res) => {
        this.setState({
          loader: false,
        });
        if (res.data.success === true) {
          this.getEmptyState();
          this.setState({
            ...this.state,
            email_sent: true,
            loader: false,
          });
        } else {
          this.setState({
            ...this.state,
            loader: false,
            errorText: res.data.message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
          errorText: getErrorMessage(err),
        });
      });
  };

  emailSentMessage = (
    <div>
      <p
        className={resetPasswordstyles.forgotPasswordEmailSentText}
        style={{ marginTop: '1rem' }}
      >
        Link for resetting your password has been sent to your registered email
      </p>
      <img
        src={EmailSentIcon}
        className={` ${resetPasswordstyles.forgotPasswordemailIcon}`}
        alt="Email_Icon"
      />
      <p className={`${resetPasswordstyles.forgotPasswordEmailSentText}`}>
        Already reset your password? Go to{' '}
        <Link to="/">
          <strong>Login</strong>
        </Link>
      </p>
    </div>
  );

  render() {
    const { username, loader } = this.state;

    return (
      <div>
        <div className="ct_main_bg">
          <div className="container-fluid">
            <Card
              title={
                this.state.email_sent
                  ? 'Sent Password Reset Email'
                  : 'Forgot Your Password?'
              }
            >
              {this.state.email_sent ? (
                this.emailSentMessage
              ) : (
                <Form onSubmit={this.onHandleSubmit}>
                  <p>
                    Please enter your username to reset password for Bitnimbus
                    Account
                  </p>
                  <div>
                    <TextField
                      id="username"
                      value={username}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                          username: e.target.value,
                          errorText: '',
                        })
                      }
                      placeholder="Enter your Username"
                      label="Username"
                      errorText={this.state.errorText}
                    />
                    <Link
                      to="/forgot/username"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontSize: '1rem',
                        color: '#111111',
                      }}
                    >
                      Forgot Username?
                    </Link>
                  </div>
                  <div>
                    <Button type="submit" label="Send Email" loading={loader} />
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <p className={resetPasswordstyles.forgotPasswordText}>
                      Already reset your password? Go to{' '}
                      <Link to="/">
                        <strong>Login</strong>
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
