import React from "react";
import UsersController, {
    Props,
} from "./UsersController";

export default class Users extends UsersController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {
            email,
            isFlag,
            isuser,
            number,
            userData,
            loader
        } = this.state;
        return (
            <div>
                <div className="text-end mb-4">
                    <button className="ct_custom_btn" onClick={this.openModal}>Add a User</button>
                </div>
                <div id="ct_modal_div" className={isFlag === true ? "ct_open_modal" : "ct_modal_div"} >
                    <div className="ct_payment_main">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h4 className="ct_font_22 mb-0">
                                Add a User
                            </h4>
                            <div className="ct_close_modal">
                                <div onClick={this.closeModal} className="bi bi-x">X</div>
                            </div>
                        </div>
                        <div>
                            <form action="">
                                {loader == true ?
                                    <div className="ct_center_loader2">
                                        <div className="ct_center_loader">
                                            <span className="loader"></span></div>
                                    </div> :
                                    <div>
                                        <div className="label-float mb-2">
                                            <input value={isuser} onChange={(e) => this.setState({ isuser: e.target.value })} type="text" placeholder=" " />
                                            <label>Username*</label>
                                        </div>
                                        <div className="label-float mb-2">
                                            <input type="email" value={email} onChange={(e) => this.setState({ email: e.target.value })} placeholder=" " />
                                            <label >Email*</label>
                                        </div>
                                        <div className="label-float mb-4">
                                            <input type="number" value={number} onChange={(e) => this.setState({ number: e.target.value })} placeholder=" " />
                                            <label >Number*</label>
                                        </div>
                                        <div className="ct_on_off_text">
                                            <div className="ct_switch_main">
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <span>This user will have full access to account features. This can be changed later.</span>
                                            </div>
                                        </div>
                                        <div className="ct_note_cnt">
                                            <p className="mb-0">The user will be sent an email to set their password</p>
                                        </div>
                                        <div className="ct_modal_footer text-end mt-5">
                                            <button className="ct_custom_btn" onClick={this.onHandleAddUser}>Add User</button>
                                        </div>
                                    </div>
                                }
                            </form>

                        </div>
                    </div>
                </div>
                <div className="ct_white-box h-auto">
                    <div className="p-0">
                        <div className="table-responsive">
                            <table className="table ct_table_bg table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th>
                                            UserName
                                        </th>
                                        <th>
                                            Emails Address
                                        </th>
                                        <th>
                                            Access
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData.map((ob: any) => (
                                        <tr>
                                            <td>{ob.username}</td>
                                            <td>{ob.email}</td>
                                            <td>{ob.account_access}</td>
                                            <td> <a style={{ marginRight: "8px" }} href="">User Profile</a>
                                                <a style={{ marginRight: "8px" }} href=""> User Permission</a>
                                                <a href=""> Delete</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
