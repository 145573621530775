import { BlockComponent } from "../framework/BlockComponent";
export const configJSON = require("../components/config");

export interface Props {

}

interface S {
    isBilling: any;
    isUser: any;
    isLogin: any;
    isService: any;
    isMaintenance: any;
    isSetting: any;
}

interface SS {
    id: any;
}

export default class HeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiLoginAccount: string = "";

    constructor(props: Props) {
        super(props);
        this.state = this.getEmptyState();
    }

    async componentDidMount() {
        super.componentDidMount();
        this.onHanldeClickTab();
    }

    getEmptyState = () => ({
        isBilling: true,
        isUser: false,
        isLogin: false,
        isService: false,
        isMaintenance: false,
        isSetting: false,
    });

    onHanldeClickTab = () => {
        if (window.location.pathname === `/stacksville/billing`) {
            this.setState({
                isBilling: true,
                isUser: false,
                isLogin: false,
                isService: false,
                isMaintenance: false,
                isSetting: false,
            })
        }
        else if (window.location.pathname === `/stacksville/user`) {
            this.setState({
                isUser: true,
                isBilling: false,
                isLogin: false,
                isService: false,
                isMaintenance: false,
                isSetting: false,
            })
        }
        else if (window.location.pathname === `/stacksville/login-history`) {
            this.setState({
                isLogin: true,
                isBilling: false,
                isUser: false,
                isService: false,
                isMaintenance: false,
                isSetting: false,
            })
        }
        else if (window.location.pathname === `/stacksville/service`) {
            this.setState({
                isService: true,
                isBilling: false,
                isUser: false,
                isLogin: false,
                isMaintenance: false,
                isSetting: false,
            })
        }
        else if (window.location.pathname === `/stacksville/maintenance`) {
            this.setState({
                isMaintenance: true,
                isBilling: false,
                isUser: false,
                isLogin: false,
                isService: false,
                isSetting: false,
            })
        }
        else if (window.location.pathname === `/stacksville/setting`) {
            this.setState({
                isSetting: true,
                isBilling: false,
                isUser: false,
                isLogin: false,
                isService: false,
                isMaintenance: false,
            })
        }
    }

    // Customizable Area End
}
