import React, { useState, useEffect } from 'react';
import VerifiedIcon from '../../../components/style/icons/Email_verified.svg';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Button from '../../../common/Button';
import { message as MESSAGE } from 'antd';
import Card, { CardContent } from '../../../common/Card';
import styles from '../../../common/card.module.scss';
import { getErrorMessage } from '../../../controllers/api';
export const configJSON = require('../../config');

interface VerifyEmailProps {
  history: any;
  loader: boolean;
  pushUrl: any;
  tokenValid: any;
  match: any;
}

const VerifyEmail = (props: VerifyEmailProps) => {
  //   const [errText, setErrText] = useState<{ [key: string]: string }>(emptyState)
  const [loader, setLoader] = useState<boolean>(true);
  const [emailVerified, setEmailVerified] = useState<boolean>();
  // const [error, setError] = useState<string>('')

  const { act_token } = props.match.params;
  useEffect(() => {
    const verifyEmailToken = async () => {
      try {
        //  const { token } = props.match.params
        const response = await axios({
          method: configJSON.apiGetMethod,
          url:
            configJSON.baseUrl +
            configJSON.verifyEmailEndPoint +
            `/${act_token}`,
          headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
        });
        if (response.data.success) {
          MESSAGE.success(response.data.message, 5);
          setEmailVerified(true);
        } else {
          MESSAGE.error(response.data.message, 5);
          setEmailVerified(false);
          //  setError(response.data.message || 'Invalid token')
        }
      } catch (err) {
        MESSAGE.error(getErrorMessage(err as Error), 5);
        setEmailVerified(true);
        // const pushUrl = '/'
        // props.history.push({
        //   pathname: pushUrl,
        // })
        //  setError('An error occurred while verifying the token')
      } finally {
        setLoader(false);
      }
    };
    verifyEmailToken();
  }, [act_token, props.history]);

  if (loader) {
    return (
      <div className="screen-loader-center">
        <div className="ct_center_loader">
          <span className="loader"></span>
        </div>
      </div>
    );
  }

  return (
    <Card
      title={
        emailVerified ? 'Email Verified!' : 'Your verification link has expired'
      }
    >
      <>
        <div>
          {emailVerified ? (
            <div className="text-center">
              <img
                className={styles.emailIcon}
                id="verified_banner"
                src={VerifiedIcon}
              />
              <p>Your registered email is verified successfully</p>
            </div>
          ) : (
            <div className="text-center">
              <p className={styles.subHeading}>
                Please check the latest email sent to your account or try login
                again.
              </p>
            </div>
          )}
        </div>
        <CardContent>
          <Link to="/">
            <Button type="submit" label="Login" />
          </Link>
        </CardContent>
      </>
    </Card>
  );
};

export default VerifyEmail;
