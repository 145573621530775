import { IBlock } from "../../framework/IBlock";
import { Message } from "../../framework/Message";
import { BlockComponent } from "../../framework/BlockComponent";
import { message as MESSAGE } from "antd";
import axios from "axios";
export const configJSON = require("../config");

export interface Props {
}

interface S {
    loginHistoryData: any,
    loader:any
}

interface SS {
    id: any;
}

export default class LoginHistoryController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiLoginAccount: string = "";

    constructor(props: Props) {
        super(props);
        this.state = this.getEmptyState();
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getAllLoginUserData();
    }

    getEmptyState = () => ({
        loginHistoryData : [],
        loader: true
    });

    getAllLoginUserData = () => {
        const token = localStorage.getItem("token");
        axios({
            method: configJSON.apiGetMethod,
            url: configJSON.baseUrl + configJSON.getAllLoginUserEndPointURL,
            headers: {
                "Content-Type": configJSON.formUrlenCodedContentType,
                "Authorization": `Bearer ${token}`
            }
        })
            .then((res) => {
                this.setState({
                    loader: false
                })
                if (res.data.success == true) {
                    this.setState({
                        loginHistoryData: res.data.history
                    });
                }
                else {
                    this.setState({
                        loginHistoryData: []
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loader:false
                })
                console.log({ err })
            });
    }
}
