import React, { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";

import styles from './baseauthform.module.scss';
import Banner from "../components/style/banner.png";
import Logo from "../components/style/logo.svg";

interface BaseAuthFormProps {
  children: ReactElement;
  title: string;
}

const BaseAuthForm = (props: BaseAuthFormProps) => {
    const isCompactView = useMediaQuery({
        query: "(max-width: 920px)",
    })
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!isCompactView && (
          <img id="signup_banner" src={Banner} className={styles.banner} />
        )}
        <div className={styles.content}>
          {isCompactView && (
            <div>
              <img id="signup_banner" src={Logo} className={styles.logo} />
            </div>
          )}
          <h5 className={styles.title}>{props.title}</h5>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default BaseAuthForm;
