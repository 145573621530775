import { AnySchema, ValidationError } from "yup";

class FormValidation {
    public static validateForm(schema: AnySchema, data: Record<string, any>) {
        try {
            schema.validateSync(data, {
                strict: true,
                abortEarly: false
            });
            return {};
        } catch(err) {
            if (err instanceof ValidationError) {
                const errObj: Record<string, any> = {};
                err.inner.forEach(e => {
                    const key = e?.path || 'error'
                    errObj[key] = e.message;
                });
                return errObj;
            }
            return {};
        }
    }

    public static hasErrors(errors: Record<string, any>) {
     return Object.keys(errors).length > 0;
    }
}

export default FormValidation;
