import React, { ReactElement } from "react";

import Logo from "../components/style/logo.svg";
import styles from "./card.module.scss";

interface CardProps {
  children: ReactElement;
  title?: string;
}

const Card = (props: CardProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <img className={styles.logo} id="logo" src={Logo} />
          </div>
          {props.title && <h3 className={styles.title}>{props.title}</h3>}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const CardContent = (props: CardProps) => {
  return <div style={{ marginBottom: "1.5rem" }}>{props.children}</div>;
};

export default Card;
