import React from "react";
import Card, { CardContent } from "../../common/Card";
import Button from "../../common/Button";
import styles from './closeaccount.module.scss';

const CloseAccount = () => {
  return (
    <Card>
      <div>
        <CardContent>
          <h3>Account is Closed!</h3>
        </CardContent>
        <CardContent>
          <div>
            <CardContent>
              <p className={styles.content}>
                Thank you for being a part of our journey. Hope we serve you
                the best with our services.
              </p>
            </CardContent>
          </div>
        </CardContent>
      </div>
    </Card>
  );
};

export default CloseAccount;
