import React from 'react';
import { Link } from 'react-router-dom';
import { BlockComponent } from '../../../framework/BlockComponent';
import EmailSentIcon from '../../style/icons/email_sent.svg';
import Card from '../../../common/Card';
import styles from '../../../common/card.module.scss';
import emailSentStyles from './emailSent.module.scss';
export const configJSON = require('../../config');

export interface Props {}

interface S {
  email: string;
  loader: boolean;
  email_sent: boolean;
  errorText: string;
}

interface SS {
  id: any;
}

export default class EmailSent extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
  }

  /*setState = (data: {email?:string, loader?:boolean}) => {
        if (data.email) {
            this.state.email = data.email;
        }
        if (data.loader) {
            this.state.loader = data.loader;
        }
    }*/
  getEmptyState = () => ({
    email: '',
    loader: false,
    email_sent: false,
    errorText: '',
  });

  emailSentMessage = (
    <>
      <div>
        <img src={EmailSentIcon} className={styles.emailIcon} />
        <p
          style={{
            fontWeight: '500',
          }}
          className={emailSentStyles.emailSentSubHeading}
        >
          Link to Verify Your Account has been sent to your registered email,
          Please Check Your Mail
        </p>
      </div>
      <p className={emailSentStyles.emailSentFooterClass}>
        Questions? Reach out to us at &nbsp;
        <a href="mailto:support@bitnimbus.io"> support@bitnimbus.io</a>
      </p>
    </>
  );

  render() {
    const { loader } = this.state;

    return (
      <div>
        <div className="ct_main_bg">
          <div className="container-fluid">
            {loader == true ? (
              <div className="screen-loader-center">
                <div className="ct_center_loader">
                  <span className="loader"></span>
                </div>
              </div>
            ) : (
              <Card title='Verify your email to complete the sign up'>{this.emailSentMessage}</Card>
            )}
          </div>
        </div>
      </div>
    );
  }
}
